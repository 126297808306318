import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useStore } from "../../hooks";
import { IUser } from "../../store/admin-store";
import yup from "../../yup-extended";
import { MuiFormCheckbox, MuiFormPhoneInput } from "../form-controls";

interface IUserDetailFormProps {
  user?: IUser | null;
}

export type IUserDetailForm = Pick<
  IUser,
  "password" | "fio" | "is_blocked" | "phone" | "email"
>;

const UserDetailForm: FC<IUserDetailFormProps> = ({ user }) => {
  const { adminStore, popupStore } = useStore();

  const schema = yup
    .object({
      fio: yup.string().required(),
      password: yup.string().required().min(6, "Минимум 6 символов"),
      phone: yup.string().isPhone().required(),
      is_blocked: yup.bool().required(),
      email: yup.string().required().email("Некорретный email"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUserDetailForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: user?.password || "",
      fio: user?.fio || "",
      is_blocked: user?.is_blocked || false,
      phone: user?.phone || "",
      email: user?.email || "",
    },
  });

  const onSubmit: SubmitHandler<IUserDetailForm> = (data) => {
    if (user) {
      adminStore
        .updateUser({ ...data, user_id: user?.user_id })
        .then(() => {
          popupStore.closePopup();
          adminStore.getUsers();
        })
        .catch(() => {
        });
    } else {
      adminStore
        .addUser(data)
        .then(() => {
          popupStore.closePopup();
          adminStore.getUsers();
        })
        .catch(() => {
        });
    }
  };

  return (
    <Box>
      <Typography variant="h6" mb={3}>
        {user ? "Редактирование юзера" : "Создание юзера"}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              error={!!errors.email}
              helperText={errors.email?.message}
              disabled={!!user}
              {...register("email")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              id="fio"
              label="ФИО"
              autoFocus
              error={!!errors.fio}
              helperText={errors.fio?.message}
              {...register("fio")}
            />
          </Grid>
          <Grid item xs={6}>
            <MuiFormPhoneInput
              control={control}
              helperText={errors.phone?.message}
              error={!!errors.phone}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Пароль"
              id="password"
              error={!!errors.password}
              helperText={errors.password?.message}
              {...register("password")}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiFormCheckbox
              control={control}
              name="is_blocked"
              error={errors.is_blocked}
              label="Заблокирован"
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );
};

export default observer(UserDetailForm);
