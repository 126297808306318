import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";

export interface IGoodsTokens {
  records: Array<IGoodsItem>;
  page: number;
  limit: number;
  totalCount: number;
}

export interface IGoodsItem {
  id?: number;
  wbClientId?: number | null;
  wbClientLabel?: string | null;
  ozonClientId?: number | null;
  ozonClientLabel?: string | null;
  brand?: string | null;
  subject?: string | null;
  sellerArticle?: string | null;
  wbArticle?: string | null;
  ozonSku?: string | null;
  description?: string | null;
  size?: string | null;
  barcode?: string | null;
  rentalPeriod?: string | null;
  deliveryTime?: string | null;
  costPrice?: string | null;
  priceApplicationDate?: string | null;
  manager?: string | null;
  active?: boolean;
}

interface IPaginationParams {
  limit: number;
  page: number;
}

export interface IFilters {
  wbClientId?: string;
  ozonClientId?: string;
  active?: boolean;
  wbArticle?: string;
  ozonSku?: string;
  manager?: string;
}

export default class UserStore {
  public goods: Array<IGoodsItem> | null = null;

  public paginationParams: IPaginationParams = { limit: 10, page: 1 };

  public totalCount: number = 0;

  public filters: IFilters = {};

  public isLoading: boolean = false;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  public setGoods = (goods: Array<IGoodsItem>) => {
    this.goods = goods;
  };

  public setPaginationParams = (params: Partial<IPaginationParams>) => {
    this.paginationParams = { ...this.paginationParams, ...params };
  };

  public setTotalCount = (count: number) => {
    this.totalCount = count;
  };

  public setFilters = (filters: IFilters) => {
    this.filters = filters;
  };

  public fetchGoods = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IGoodsTokens>("getGoods", undefined, {
        ...this.paginationParams,
        filter: Object.keys(this.filters).length ? this.filters : undefined,
      })
      .then((data) => {
        this.setGoods(data.records);
        if (this.totalCount !== data.totalCount) {
          this.setTotalCount(data.totalCount);
        }
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public addGood = async (data: IGoodsItem): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IGoodsTokens>("addGood", {
        limit: String(this.paginationParams.limit),
        page: String(this.paginationParams.page)
      }, data)
      .then(async (data) => {
        this.setGoods(data.records);
        this.rootStore.notificationStore.addNotification({
          text: "Товар успешно добавлен!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public editGood = async (data: IGoodsItem): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IGoodsTokens>("editGood", {
        limit: String(this.paginationParams.limit),
        page: String(this.paginationParams.page)
      }, data)
      .then(async (data) => {
        this.setGoods(data.records);
        this.rootStore.notificationStore.addNotification({
          text: "Товар успешно изменен!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public deleteGood = async (id: number): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IGoodsTokens>("deleteGood", {
        limit: String(this.paginationParams.limit),
        page: String(this.paginationParams.page)
      }, {
        id,
      })
      .then(async (data) => {
        this.setGoods(data.records);
        if (this.totalCount !== data.totalCount) {
          this.setTotalCount(data.totalCount);
        }
        this.rootStore.notificationStore.addNotification({
          text: "Товар успешно удален!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });
        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public exportFile = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Response>("exportGoods", undefined, undefined, { responseType: "blob" }, true)
      .then(async (data) => {
        const disposition = data.headers.get("Content-Disposition");
        const fileName = disposition?.split("filename=")[1];
        const blobFile = await data.blob();

        const blob = new Blob([blobFile], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName || "goods_details.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);

        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };

  public importFile = async (formData: FormData): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<IGoodsTokens>("importGoods", {
        limit: String(this.paginationParams.limit),
        page: String(this.paginationParams.page)
      }, formData)
      .then(async (data) => {
        this.setGoods(data.records);
        if (this.totalCount !== data.totalCount) {
          this.setTotalCount(data.totalCount);
        }

        this.rootStore.notificationStore.addNotification({
          text: "Товары успешно импортированы!",
          options: {
            autoHideDuration: 2000,
          },
          type: "success",
        });

        this.setLoading(false);
      })
      .catch(() => this.setLoading(false));
  };
}
