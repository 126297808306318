import { action,makeObservable, observable } from "mobx";

import RequestFactory from "../request/request-factory";
import AdminStore from "./admin-store";
import GoodsStore from "./goods-store";
import NotificationStore from "./notifications-store";
import PopupStore from "./popup-store";
import ReferenceBooksStore from "./reference-books-store";
import ReportsStore from "./reports-store";
import SellerTokensStore from "./seller-tokens-store";
import UserNotificationsStore from "./user-notifications-store";
import UserStore from "./user-store";

export class RootStore {
  createRequest: RequestFactory["createRequest"] = Promise.resolve;

  isLoading: boolean = false;

  userStore: UserStore;

  reportsStore: ReportsStore;
  notificationStore: NotificationStore;
  popupStore: PopupStore;
  adminStore: AdminStore;
  sellerTokensStore: SellerTokensStore;
  userNotificationsStore: UserNotificationsStore;
  goodsStore: GoodsStore;
  referenceBooks: ReferenceBooksStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.reportsStore = new ReportsStore(this);
    this.notificationStore = new NotificationStore(this);
    this.popupStore = new PopupStore(this);
    this.adminStore = new AdminStore(this);
    this.sellerTokensStore = new SellerTokensStore(this);
    this.userNotificationsStore = new UserNotificationsStore(this);
    this.goodsStore = new GoodsStore(this);
    this.referenceBooks = new ReferenceBooksStore(this);

    makeObservable(this, {
      isLoading: observable,
      setLoading: action,
      setCreateRequest: action,
    });
  }

  public setCreateRequest(createRequest: RequestFactory["createRequest"]) {
    this.createRequest = createRequest;
  }

  public setLoading = (value: boolean) => {
    this.isLoading = value;
  };
}
