import { makeAutoObservable } from "mobx";

import { ISelectOptionType } from "../types";
import { RootStore } from "./root-store";

export interface IUser {
  email: string;
  fio: string;
  phone: string;
  is_admin: boolean;
  is_blocked: boolean;
  is_powerbi: boolean;
  locked: boolean;
  password: string;
  user_id: number;
  utime: string;
  wtime: string;
}

export interface IReport extends IUser {
  type_marketplace: string;
  type_report: string;
  url_report: string;
  is_powerbi: boolean;
  note_m: string;
  note_r: string;
}

interface IMarketplaceTypes {
  note: string;
  type: string;
  utime: string;
  wtime: string;
}

interface IReportTypes {
  note: string;
  type: string;
  utime: string;
  wtime: string;
}

export default class AdminStore {
  public users: Array<IUser> = [];

  public reports: Array<IReport> = [];
  public reportTypes: Array<ISelectOptionType> = [];
  public marketplaceTypes: Array<ISelectOptionType> = [];

  public isLoading: boolean = false;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  private setLoading = (value: boolean) => {
    this.isLoading = value;
  };

  private setUsers = (users: Array<IUser>) => {
    this.users = users;
  };

  private setReports = (reports: Array<IReport>) => {
    this.reports = reports;
  };

  private setReportTypes = (types: Array<IMarketplaceTypes>) => {
    this.reportTypes = types.map((type) => ({
      value: type.type,
      label: type.note,
    }));
  };

  private setMarketplaceTypes = (types: Array<IReportTypes>) => {
    this.marketplaceTypes = types.map((type) => ({
      value: type.type,
      label: type.note,
    }));
  };

  public getReportTypes = async (): Promise<void> => {
    this.rootStore.setLoading(true);
    return this.rootStore
      .createRequest<Array<IReportTypes>>("adminGetReportTypes")
      .then((types) => {
        this.setReportTypes(types);
      })
      .finally(() => this.rootStore.setLoading(false));
  };

  public getMarketplaceTypes = async (): Promise<void> => {
    this.rootStore.setLoading(true);
    return this.rootStore
      .createRequest<Array<IMarketplaceTypes>>("adminGetMarketplaceTypes")
      .then((types) => {
        this.setMarketplaceTypes(types);
      })
      .finally(() => this.rootStore.setLoading(false));
  };

  public getUsers = async (): Promise<void> => {
    this.rootStore.setLoading(true);
    return this.rootStore
      .createRequest<Array<IUser>>("adminGetUsers")
      .then((users) => {
        this.setUsers(users);
      })
      .finally(() => this.rootStore.setLoading(false));
  };

  public updateUser = async (data: Partial<IUser>): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<IUser>>("adminUpdateUser", undefined, data)
      .then(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Данные пользователя успешно обновлены!",
          type: "success",
        });
      })
      .finally(() => this.setLoading(false));
  };

  public addUser = async (data: Partial<IUser>): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<IUser>>("adminAddUser", undefined, data)
      .then(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Пользователь успешно создан!",
          type: "success",
        });
      })
      .finally(() => this.setLoading(false));
  };

  public getReports = async (): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest<Array<IReport>>("adminGetReports")
      .then((reports) => {
        this.setReports(reports);
      })
      .finally(() => this.setLoading(false));
  };

  public addReport = async (data: Partial<IReport>): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest("adminAddReport", undefined, data)
      .then(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Отчет успешно добавлен!",
          type: "success",
        });
      })
      .finally(() => this.setLoading(false));
  };

  public updateReport = async (data: Partial<IReport>): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest("adminUpdateReport", undefined, data)
      .then(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Отчет успешно обновлены!",
          type: "success",
        });
      })
      .finally(() => this.setLoading(false));
  };

  public deleteReport = async (data: Partial<IReport>): Promise<void> => {
    this.setLoading(true);
    return this.rootStore
      .createRequest("adminDeleteReport", undefined, data)
      .then(() => {
        this.rootStore.notificationStore.addNotification({
          text: "Отчет успешно удален!",
          type: "success",
        });
      })
      .finally(() => this.setLoading(false));
  };
}
