import {
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FC, memo } from "react";
import { Control, Controller, FieldError } from "react-hook-form";

interface IMuiFormSelectProps {
  name: string;
  label?: string;
  options: Array<{ value: string; label: string }>;
  error?: FieldError;
  variant?: SelectProps["variant"];
  control: Control<any>;
  required?: boolean;
}

const MuiFormSelect: FC<IMuiFormSelectProps> = ({
  options,
  label,
  name,
  error,
  control,
  required,
  variant = "outlined",
}) => {
  return (
    <FormControl
      error={Boolean(error?.message)}
      sx={{ width: "100%" }}
      variant={variant}
    >
      {label && <InputLabel required={required} id={`${name}-label`}>{label}</InputLabel>}
      <Controller
        render={({ field }) => (
          <Select
            MenuProps={{ variant: "menu", disableScrollLock: true }}
            id={name}
            input={
              variant === "outlined" ? (
                <OutlinedInput label={label} />
              ) : undefined
            }
            labelId={`${name}-label`}
            {...field}
          >
            {options.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        name={name}
        control={control}
      />
      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default memo(MuiFormSelect);
