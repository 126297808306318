import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SmsIcon from "@mui/icons-material/Sms";
import TelegramIcon from "@mui/icons-material/Telegram";
import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { FC, ReactElement, useState } from "react";

interface IAction {
  href: string;
  icon: ReactElement;
  name: string;
}

const actions: Array<IAction> = [
  {
    icon: <TelegramIcon />,
    name: "Написать в Telegram",
    href: "https://t.me/effstepsalesdir",
  },
  {
    icon: <AlternateEmailIcon />,
    name: "Написать на почту",
    href: "mailto:Effstepcorp@gmail.com",
  },
];

const actionStyle: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiSpeedDialAction-staticTooltipLabel": {
    width: "200px",
  },
});

const createAndClickLink = (href: string, target?: string) => {
  const newlink = document.createElement("a");
  newlink.setAttribute("href", href);
  newlink.target = "_blank";
  document.body.appendChild(newlink);
  newlink.click();
  newlink.remove();
};

const Support: FC = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <SpeedDial
        ariaLabel="Support"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<SmsIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            sx={actionStyle}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => {
              createAndClickLink(action.href);
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default Support;
