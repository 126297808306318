import { makeAutoObservable } from "mobx";

import { ISelectOptionType, MarketplacesType } from "../types";
// eslint-disable-next-line import/no-cycle
import { RootStore } from "./root-store";

export interface IClientId {
  id: number;
  name: string;
}

type ClientIds = Record<string, Array<IClientId>>;

export default class ReferenceBooksStore {
  public clientIds: Record<MarketplacesType, Array<ISelectOptionType>> | undefined = undefined;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  public serClientIds = (clientIds: ClientIds) => {
    this.clientIds = {
      WB: clientIds.wb.map(wbClientIds => ({ value: String(wbClientIds.id), label: wbClientIds.name })),
      OZON: clientIds.ozon.map(ozonClientIds => ({ value: String(ozonClientIds.id), label: ozonClientIds.name }))
    };
  };

  public fetchClientIds = async (): Promise<void> => {
    return this.rootStore
      .createRequest<ClientIds>("getReferenceBooks")
      .then((data) => {
        this.serClientIds(data);
      })
      .catch(() => {
      });
  };
}
