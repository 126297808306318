import { MarketplacesType } from "./types";

export const TOKEN_STORAGE_NAME = "authorization_token";
export const IS_ADMIN_STORAGE_NAME = "is_user_admin";
export const MARKETPLACE_STORAGE_NAME = "marketplace_type";

export const MARKETPLACES: Array<{
  label: string;
  value: MarketplacesType;
}> = [
  {
    label: "Wildberries",
    value: "WB",
  },
  {
    label: "Ozon",
    value: "OZON",
  },
];


export const dateMask = {
  mask: {
    mask: "0000-00-00",
  }
};

export const priceMask = {
  mask: {
    mask: Number,
    scale: 2,
    radix: "."
  }
};

export const numberMask = {
  mask: {
    mask: Number,
  }
};
