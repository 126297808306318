import HomeIcon from "@mui/icons-material/Home";
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from "@mui/material";
import { FC } from "react";

interface IBreadcrumbs {
  pageName: string;
}

const Breadcrumbs: FC<IBreadcrumbs> = ({ pageName }) => (
  <MuiBreadcrumbs sx={{ mb: 2 }}>
    <Link
      underline="hover"
      sx={{ display: "flex", alignItems: "center" }}
      color="inherit"
      href="/"
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
      Главная
    </Link>

    <Typography
      sx={{ color: "text.primary", display: "flex", alignItems: "center" }}
    >
      {pageName}
    </Typography>
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
