// eslint-disable-next-line import/no-cycle
import { makeAutoObservable } from "mobx";

import { RootStore } from "./root-store";

export interface IUserNotification {
  level: "INFO" | "WARNING" | "ERROR";
  message: string;
  title: string;
  type: "global" | "personal";
  buttonLink?: string;
  buttonText?: string;
}

export interface IUserNotifications {
  global: Array<IUserNotification>;
  personal: Array<IUserNotification>;
}

export default class NotificationsStore {
  public userNotifications: IUserNotifications | null = null;

  protected rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  public setUserNotifications = (notifications: Array<IUserNotification>) => {
    this.userNotifications = {
      global: notifications.filter(
        (notification) => notification.type === "global"
      ),
      personal: notifications.filter(
        (notification) => notification.type === "personal"
      ),
    };
  };

  public fetchUserNotifications = async (): Promise<void> => {
    return this.rootStore
      .createRequest<Array<IUserNotification>>("getUserNotifications")
      .then((notifications) => {
        this.setUserNotifications(notifications);
      })
      .catch(() => {});
  };
}
