import { Stack, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import { FC, SyntheticEvent, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Breadcrumbs from "../breadcrumbs";
import Goods from "../goods";
import SellerTokens from "../seller-tokens";

type TabsCodes = "sellerTokens" | "goods";

const TABS: Array<TabsCodes> = ["sellerTokens", "goods"];

const Settings: FC = () => {
  const { id } = useParams<{ id: TabsCodes }>();
  const { push } = useHistory();
  const [tabId, setTabId] = useState(id ? TABS.indexOf(id) : 0);

  const onChange = (event: SyntheticEvent, id: number) => {
    const value = TABS[id];

    push(`/settings/${value}`);
    setTabId(id);
  };

  const renderTab = useMemo(() => {
    const tabCode = TABS[tabId];

    if (tabCode === "sellerTokens") {
      return <SellerTokens />;
    }

    if (tabCode === "goods") {
      return <Goods />;
    }
  }, [tabId]);

  return (
    <Box>
      <Breadcrumbs pageName="Настройки" />
      <Stack direction="column" gap={2}>
        <Tabs value={tabId} onChange={onChange}>
          <Tab label="Токены" id="sellerTokens" />
          <Tab label="Товары" id="goods" />
        </Tabs>

        {renderTab}
      </Stack>
    </Box>
  );
};

export default Settings;
