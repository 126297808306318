import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useStore } from "../../hooks";
import { IReport } from "../../store/admin-store";
import yup from "../../yup-extended";
import { MuiFormCheckbox, MuiFormSelect } from "../form-controls";
import Loader from "../loader/loader";
import UserReportsList from "../user-reports-list";

export type IUserReportForm = Pick<
  IReport,
  "type_report" | "type_marketplace" | "url_report" | "is_powerbi"
>;

interface IUserReportsFormProps {
  userId: number;
}

const UserReportsForm: FC<IUserReportsFormProps> = ({ userId }) => {
  const { adminStore, popupStore, notificationStore } = useStore();
  const [showAddReportForm, setShowAddReportForm] = useState(false);
  const [mode, setMode] = useState<"update" | "add">("update");

  const schema = yup
    .object({
      type_report: yup.string().required(),
      type_marketplace: yup.string().required(),
      url_report: yup.string().required(),
      is_powerbi: yup.bool().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IUserReportForm>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!adminStore.reports.length) {
      adminStore.getReports();
    }
    if (!adminStore.marketplaceTypes.length) {
      adminStore.getMarketplaceTypes();
    }
    if (!adminStore.reports.length) {
      adminStore.getReportTypes();
    }
  }, [adminStore]);

  const userReports = useMemo(
    () => adminStore.reports.filter((report) => report.user_id === userId),
    [userId, adminStore.reports]
  );

  const onToggleShowForm = () => {
    setValue("type_marketplace", "");
    setValue("type_report", "");
    setValue("url_report", "");
    setValue("is_powerbi", false);
    setMode("add");
    setShowAddReportForm(true);
  };

  const onUpdateReport = (report: IReport) => {
    setValue("type_marketplace", report.type_marketplace);
    setValue("type_report", report.type_report);
    setValue("url_report", report.url_report);
    setValue("is_powerbi", report.is_powerbi);

    setMode("update");
    setShowAddReportForm(true);
  };

  const onDeleteReport = (report: IReport) => {
    adminStore
      .deleteReport({ ...report, user_id: userId })
      .then(() => {
        adminStore.getReports();
      })
      .catch(() => {
      });
  };

  const onSubmit: SubmitHandler<IUserReportForm> = useCallback(
    (data) => {
      if (mode === "add") {
        const isExistsReport = userReports.find(
          (userReport) =>
            userReport.type_marketplace === data.type_marketplace &&
            userReport.type_report === data.type_report
        );

        if (isExistsReport) {
          notificationStore.addNotification({
            text: "Отчет с данными параметрами уже существует!",
            type: "error",
          });
        } else {
          adminStore.addReport({ ...data, user_id: userId }).then(() => {
            adminStore.getReports();
            popupStore.closePopup();
          });
        }
      }

      if (mode === "update") {
        adminStore
          .updateReport({ ...data, user_id: userId })
          .then(() => {
            adminStore.getReports();
            popupStore.closePopup();
          })
          .catch(() => {
          });
      }
    },
    [mode, userReports, notificationStore, adminStore, popupStore, userId]
  );

  return (
    <Box width="700px">
      <Loader isLoading={adminStore.isLoading} />

      <Typography variant="h6" mb={3}>
        Редактирование отчетов
      </Typography>
      <UserReportsList
        reports={userReports}
        onUpdateReport={onUpdateReport}
        onDeleteReport={onDeleteReport}
      />
      <Button onClick={onToggleShowForm}>Добавить репорт</Button>
      {showAddReportForm && (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <MuiFormSelect
                label="Тип отчета"
                options={adminStore.reportTypes}
                error={errors.type_report}
                name="type_report"
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiFormSelect
                name="type_marketplace"
                options={adminStore.marketplaceTypes}
                label="Тип Магазин"
                control={control}
                error={errors.type_marketplace}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="none"
                fullWidth
                id="url_report"
                label="Ссылка на отчет"
                error={!!errors.url_report}
                helperText={errors.url_report?.message}
                {...register("url_report")}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiFormCheckbox
                control={control}
                name="is_powerbi"
                error={errors.url_report}
                label="Отчет переведен на базу"
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {mode === "add" ? "Добавить" : "Обновить"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default observer(UserReportsForm);
