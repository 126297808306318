const key = "effstep";

const storage = {
  get: (name: string) => localStorage.getItem(`${key}.${name}`),
  set: (name: string, value: string) =>
    localStorage.setItem(`${key}.${name}`, value),
  remove: (name: string) => localStorage.removeItem(`${key}.${name}`),
  clear: () => {
    Object.keys(localStorage)
      .filter((item) => item.startsWith(key))
      .forEach((item) => localStorage.removeItem(item));
  },
};

export default storage;
