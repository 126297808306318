const Locale = {
  headerCells: [
    {
      name: "ID WB",
      width: "5%",
    },
    {
      name: "ID OZON",
      width: "5%",
    },
    {
      name: "Бренд",
      width: "10%",
    },
    {
      name: "Предмет",
      width: "10%",
    },
    {
      name: "Артикул продавца",
      width: "10%",
    },
    {
      name: "Артикул WB",
      width: "8%",
    },
    {
      name: "OZON SKU",
      width: "8%",
    },
    {
      name: "Описание",
      width: "8%",
    },
    {
      name: "Размер",
      width: "5%",
    },
    {
      name: "Баркод",
      width: "8%",
    },
    {
      name: "Срок покатушек, дн",
      width: "5%",
    },
    {
      name: "Срок поставки, дн",
      width: "5%",
    },
    {
      name: "Себестоимость",
      width: "7%",
    },
    {
      name: "Дата применения цены",
      width: "20%",
    },
    {
      name: "Менеджер",
      width: "8%",
    },
    {
      name: "Активный",
      width: "5%",
    },
    {
      name: "edit",
      width: "5%",
    },
    {
      name: "delete",
      width: "5%",
    },
  ],
};

export default Locale;
