import IMask from "imask";
import { useCallback, useEffect, useRef, useState } from "react";

import type { Mask, UseMaskProps, UseMaskReturn } from "./use-mask.types";

const useMask = ({
  value,
  onAccept,
  onComplete,
  maskOptions,
}: UseMaskProps): UseMaskReturn => {
  const [maskValue, setMaskValue] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);
  const maskRef = useRef<Mask | null>(null);
  const previousValue = useRef<string | undefined>(undefined);

  const handleAccept = useCallback(() => {
    const mask = maskRef.current;

    if (!mask) {
      return;
    }

    const { value, unmaskedValue, typedValue } = mask;

    setMaskValue(value);

    onAccept?.(unmaskedValue, value, typedValue);
  }, [onAccept]);

  const handleComplete = useCallback(() => {
    const mask = maskRef.current;

    if (!mask) {
      return;
    }

    const { value, unmaskedValue, typedValue } = mask;

    onComplete?.(unmaskedValue, value, typedValue);
  }, [onComplete]);

  const destroy = useCallback(() => {
    if (!maskRef.current) {
      return;
    }

    maskRef.current?.destroy();
    maskRef.current = null;
  }, [maskRef]);

  // CREATE AND UPDATE MASK
  useEffect(() => {
    const input = inputRef.current;

    if (!input || !maskOptions) {
      destroy();
    }

    const mask = maskRef.current;

    if (!mask) {
      if (input && maskOptions) {
        maskRef.current = IMask(input, maskOptions);
        handleAccept();
      }
    } else {
      mask.updateOptions(maskOptions);
    }
  }, [destroy, handleAccept, maskOptions]);

  // SUBSCRIBERS
  useEffect(() => {
    if (!maskRef.current) {
      return;
    }

    const mask = maskRef.current;

    mask.on("accept", handleAccept);
    mask.on("complete", handleComplete);

    return () => {
      mask.off("accept", handleAccept);
      mask.off("complete", handleComplete);
    };
  }, [handleAccept, handleComplete]);

  // SYNC OUTER VALUE AND MASK VALUE
  useEffect(() => {
    const mask = maskRef.current;

    if (mask && value !== undefined && value !== previousValue.current) {
      mask.value = value;
      previousValue.current = value;
    }
  }, [value]);

  useEffect(() => () => destroy(), [destroy]);

  return {
    maskValue,
    inputRef,
    maskRef,
  };
};

export default useMask;
