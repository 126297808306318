import { Box } from "@mui/material";
import MuiPhoneNumber, { MuiPhoneNumberProps } from "material-ui-phone-number";
import { FC, memo } from "react";
import { Control, Controller } from "react-hook-form";

interface IMuiFormPhoneInput {
  control: Control<any>;
  name?: string;
  label?: string;
  countries?: MuiPhoneNumberProps["onlyCountries"];
  defaultCountry?: MuiPhoneNumberProps["defaultCountry"];

  [otherProps: string]: any;
}

const MuiFormPhoneInput: FC<IMuiFormPhoneInput> = ({
  countries = ["ru", "kg", "by"],
  defaultCountry = "ru",
  control,
  label = "Телефон",
  name = "phone",
  ...otherProps
}) => {
  return (
    <Box sx={{ mt: 2, mb: 1 }}>
      <Controller
        render={({ field: { onChange, value } }) => (
          <MuiPhoneNumber
            label={label}
            onlyCountries={countries || ["ru", "kg", "by"]}
            defaultCountry={defaultCountry || "ru"}
            variant="outlined"
            onChange={(v) => onChange(v)}
            value={value}
            fullWidth
            {...otherProps}
          />
        )}
        name={name}
        control={control}
      />
    </Box>
  );
};

export default memo(MuiFormPhoneInput);
