import { createContext } from "react";

import RequestFactory from "./request/request-factory";
import { MarketplacesType } from "./types";

export interface IContext {
  createRequest: RequestFactory["createRequest"];
  marketplaceType?: string;
  setMarketplaceType: (marketplaceType: MarketplacesType) => void;
}

export const CoreContext = createContext<IContext>({} as IContext);
export const CoreContextProvider = CoreContext.Provider;
