import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Collapse,Grid, Paper, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useStore } from "../../../hooks";
import { IFilters } from "../../../store/goods-store";
import { clearEmptyFieldiInObject } from "../../../utils/utils";
import yup from "../../../yup-extended";
import MuiFormRadioGroup from "../../form-controls/mui-form-radio-group";
import MuiFormSelect from "../../form-controls/mui-form-select";
import goods from "../goods";

interface IGoodsFilters {
  show?: boolean;
  onCloseFilters: () => void;
}

enum RadioGroupValue {
  all = "all",
  active = "active",
  notActive = "notActive",
}

interface IFilterData extends Omit<IFilters, "active"> {
  active?: RadioGroupValue;
}

const GoodsFilters: FC<IGoodsFilters> = ({ show = false, onCloseFilters }) => {
  const {
    goodsStore: { setFilters, fetchGoods, filters, goods },
    referenceBooks: { clientIds, fetchClientIds }
  } = useStore();

  const schema = yup.object({
    wbClientId: yup.string(),
    ozonClientId: yup.string(),
    wbArticle: yup.string(),
    ozonSku: yup.string(),
    manager: yup.string(),
    active: yup
      .string()
      .oneOf<RadioGroupValue>([
        RadioGroupValue.all,
        RadioGroupValue.active,
        RadioGroupValue.notActive,
      ]),
  });

  const methods = useForm<IFilterData>({
    resolver: yupResolver<IFilterData>(schema),
    defaultValues: {
      wbClientId: filters?.wbClientId || "",
      ozonClientId: filters?.ozonClientId || "",
      wbArticle: filters?.wbArticle || "",
      ozonSku: filters?.ozonSku || "",
      manager: filters?.manager || "",
      active:
        filters?.active === false
          ? RadioGroupValue.notActive
          : filters?.active === true
            ? RadioGroupValue.active
            : RadioGroupValue.all,
    },
  });

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  const radioGroupItems = useMemo(
    () => [
      { label: "Все", value: "all" },
      { label: "Да", value: "active" },
      { label: "Нет", value: "notActive" },
    ],
    []
  );

  const onSubmit: SubmitHandler<IFilterData> = (data) => {
    const transformedData = clearEmptyFieldiInObject<IFilters>(data);

    let activeValue = undefined;
    if (data.active !== undefined && data.active !== "all") {
      activeValue = data.active === RadioGroupValue.active;
    }

    setFilters({ ...transformedData, active: activeValue });
  };

  const onCancel = useCallback(() => onCloseFilters(), [onCloseFilters]);

  const onReset = useCallback(() => {
    reset();
    setFilters({});
  }, [setFilters, reset]);

  useEffect(() => {
    if (!clientIds) {
      fetchClientIds();
    }
  }, [clientIds, fetchClientIds]);

  useEffect(() => {
    if (Object.keys(filters).length) {
      fetchGoods();
    }
  }, [filters, fetchGoods]);

  return (
    <Collapse in={show}>
      <Paper sx={{ p: 2, borderRadius: "8px", mb: 2 }} elevation={2}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "16px",
          }}
        >
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <MuiFormSelect
                label="ID WB"
                variant="standard"
                options={clientIds?.WB || []}
                error={errors.wbClientId}
                name="wbClientId"
                control={control}
              />
            </Grid>
            <Grid item sm={4}>
              <MuiFormSelect
                label="ID OZON"
                variant="standard"
                options={clientIds?.OZON || []}
                error={errors.ozonClientId}
                name="ozonClientId"
                control={control}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label="Артикул WB"
                variant="standard"
                fullWidth
                error={!!errors?.wbArticle}
                helperText={errors?.wbArticle?.message}
                {...register("wbArticle")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <TextField
                label="OZON SKU"
                variant="standard"
                fullWidth
                error={!!errors?.ozonSku}
                helperText={errors?.ozonSku?.message}
                {...register("ozonSku")}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                label="Менеджер"
                variant="standard"
                fullWidth
                error={!!errors?.manager}
                helperText={errors?.manager?.message}
                {...register("manager")}
              />
            </Grid>
            <Grid item sm={3}>
              <MuiFormRadioGroup
                control={control}
                name="active"
                error={errors.active}
                label="Активный"
                items={radioGroupItems}
              />
            </Grid>
          </Grid>
          <Stack gap={2} direction="row" mt={2}>
            <Button onClick={onCancel} variant="text">
              Скрыть
            </Button>
            <Button onClick={onReset} variant="text">
              Очистить
            </Button>

            <Button type="submit" variant="contained">
              Применить
            </Button>
          </Stack>
        </form>
      </Paper>
    </Collapse>
  );
};

export default observer(GoodsFilters);
