export const clearEmptyFieldiInObject = <T>(data: Record<any, any>) =>
  Object.entries(data)
    .filter(([key, value]) => {
      if (typeof value === "string") {
        return !!value;
      }
      return true;
    })
    .reduce((acc, [key, value]) => {
      //@ts-ignore
      acc[key] = value;
      return acc;
    }, {}) as T;
