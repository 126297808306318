import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Paper, Stack, TextField } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";

import { dateMask, numberMask, priceMask } from "../../../constants";
import { useStore } from "../../../hooks";
import { IGoodsItem } from "../../../store/goods-store";
import yup from "../../../yup-extended";
import { MuiFormCheckbox, MuiFormMaskedInput, MuiFormSelect } from "../../form-controls";

interface IAddFieldForm {
  onAdd: (data: IGoodsItem) => void;
  onCancel: () => void;
  defaultData?: IGoodsItem;
}

type AddGoodFormValues = Omit<IGoodsItem, "id" | "wbClientLabel" | "ozonClientLabel">;

const AddFieldForm: FC<IAddFieldForm> = ({ onAdd, onCancel, defaultData }) => {
  const {
    referenceBooks: { clientIds, fetchClientIds }
  } = useStore();

  const schema = yup.object().shape({
    wbClientId: yup.number()
      .when("ozonClientId", {
        is: (ozonClientId: number) => !ozonClientId,
        then: (schema) => schema.required(),
      }),
    ozonClientId: yup.number()
      .when("wbClientId", {
        is: (wbClientId: number) => !wbClientId,
        then: (schema) => schema.required(),
      }),
    brand: yup.string().nullable(),
    subject: yup.string().nullable(),
    sellerArticle: yup.string().required(),
    wbArticle: yup.string()
      .when("ozonSku", {
        is: (ozonSku: string) => !ozonSku,
        then: (schema) => schema.required(),
      }),
    ozonSku: yup.string()
      .when("wbArticle", {
        is: (wbArticle: string) => !wbArticle,
        then: (schema) => schema.required(),
      }),
    description: yup.string().nullable(),
    size: yup.string().nullable(),
    barcode: yup.string().nullable(),
    rentalPeriod: yup.string().nullable(),
    deliveryTime: yup.string().nullable(),
    costPrice: yup.string().required(),
    priceApplicationDate: yup.string().required(),
    manager: yup.string().nullable(),
    active: yup.boolean(),
  }, [["wbClientId", "ozonClientId"], ["wbArticle", "ozonSku"]]);

  const methods = useForm<AddGoodFormValues>({
    resolver: yupResolver<AddGoodFormValues>(schema),
    defaultValues: {
      wbClientId: defaultData?.wbClientId || undefined,
      ozonClientId: defaultData?.ozonClientId || undefined,
      brand: defaultData?.brand || undefined,
      subject: defaultData?.subject || undefined,
      sellerArticle: defaultData?.sellerArticle || undefined,
      wbArticle: defaultData?.wbArticle || undefined,
      ozonSku: defaultData?.ozonSku || undefined,
      description: defaultData?.description || undefined,
      size: defaultData?.size || undefined,
      barcode: defaultData?.barcode || undefined,
      rentalPeriod: defaultData?.rentalPeriod || undefined,
      deliveryTime: defaultData?.deliveryTime || undefined,
      costPrice: defaultData?.costPrice || undefined,
      priceApplicationDate: defaultData?.priceApplicationDate || undefined,
      manager: defaultData?.manager || undefined,
      active: defaultData?.active || false,
    },
  });

  const {
    register,
    reset,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = methods;

  const wbClientIdValue = useWatch({ name: "wbClientId", control });
  const ozonClientIdValue = useWatch({ name: "ozonClientId", control });
  const wbArticleValue = useWatch({ name: "wbArticle", control });
  const ozonSkuValue = useWatch({ name: "ozonSku", control });

  useEffect(() => {
    if (wbClientIdValue || ozonClientIdValue) {
      trigger(["wbClientId", "ozonClientId"]);
    }
  }, [wbClientIdValue, ozonClientIdValue, trigger]);

  useEffect(() => {
    if (wbArticleValue || ozonSkuValue) {
      trigger(["wbArticle", "ozonSku"]);
    }
  }, [wbArticleValue, ozonSkuValue, trigger]);


  useEffect(() => {
    if (!clientIds) {
      fetchClientIds();
    }
  }, [clientIds, fetchClientIds]);

  const onSubmit: SubmitHandler<AddGoodFormValues> = (data) => {
    if (defaultData?.id) {
      onAdd({ id: defaultData.id, ...data });
    } else {
      onAdd(data);
    }

    reset();
  };

  const dateMaskMemo = useMemo(() => dateMask, []);
  const priceMaskMemo = useMemo(() => priceMask, []);
  const numberMaskMemo = useMemo(() => numberMask, []);

  return (
    <Paper sx={{ p: 2, borderRadius: "8px" }} elevation={2}>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "16px",
        }}
      >
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <MuiFormSelect
              label="ID WB"
              variant="standard"
              required
              options={clientIds?.WB || []}
              error={errors.wbClientId}
              name="wbClientId"
              control={control}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormSelect
              label="ID OZON"
              variant="standard"
              required
              options={clientIds?.OZON || []}
              error={errors.ozonClientId}
              name="ozonClientId"
              control={control}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Бренд"
              variant="standard"
              fullWidth
              error={!!errors?.brand}
              helperText={errors?.brand?.message}
              {...register("brand")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Предмет"
              variant="standard"
              fullWidth
              error={!!errors?.subject}
              helperText={errors?.subject?.message}
              {...register("subject")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <TextField
              label="Артикул продавца"
              required
              variant="standard"
              fullWidth
              error={!!errors?.sellerArticle}
              helperText={errors?.sellerArticle?.message}
              {...register("sellerArticle")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Артикул WB"
              required
              variant="standard"
              fullWidth
              error={!!errors?.wbArticle}
              helperText={errors?.wbArticle?.message}
              {...register("wbArticle")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="OZON SKU"
              required
              variant="standard"
              fullWidth
              error={!!errors?.ozonSku}
              helperText={errors?.ozonSku?.message}
              {...register("ozonSku")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Описание"
              variant="standard"
              fullWidth
              error={!!errors?.description}
              helperText={errors?.description?.message}
              {...register("description")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <TextField
              label="Размер"
              variant="standard"
              fullWidth
              error={!!errors?.size}
              helperText={errors?.size?.message}
              {...register("size")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Баркод"
              variant="standard"
              fullWidth
              error={!!errors?.barcode}
              helperText={errors?.barcode?.message}
              {...register("barcode")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormMaskedInput
              mask={numberMaskMemo}
              label="Срок покатушек, дн"
              variant="standard"
              fullWidth
              error={!!errors?.rentalPeriod}
              helperText={errors?.rentalPeriod?.message}
              {...register("rentalPeriod")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormMaskedInput
              mask={numberMaskMemo}
              label="Срок поставки, дн"
              variant="standard"
              fullWidth
              error={!!errors?.deliveryTime}
              helperText={errors?.deliveryTime?.message}
              {...register("deliveryTime")}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <MuiFormMaskedInput
              mask={priceMaskMemo}
              label="Себестоимость"
              required
              variant="standard"
              fullWidth
              error={!!errors?.costPrice}
              helperText={errors?.costPrice?.message}
              {...register("costPrice")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormMaskedInput
              mask={dateMaskMemo}
              label="Дата применения цены"
              placeholder="ГГГГ-ММ-ДД"
              required
              variant="standard"
              fullWidth
              error={!!errors?.priceApplicationDate}
              helperText={errors?.priceApplicationDate?.message}
              {...register("priceApplicationDate")}
            />
          </Grid>
          <Grid item sm={3}>
            <TextField
              label="Менеджер"
              variant="standard"
              fullWidth
              error={!!errors?.manager}
              helperText={errors?.manager?.message}
              {...register("manager")}
            />
          </Grid>
          <Grid item sm={3}>
            <MuiFormCheckbox
              control={control}
              name="active"
              error={errors.active}
              label="Активный"
            />
          </Grid>
        </Grid>
        <Stack gap={2} direction="row" mt={2}>
          <Button onClick={onCancel} variant="text">
            Отменить
          </Button>

          <Button type="submit" variant="contained">
            {Boolean(defaultData) ? "Редактировать" : "Добавить"}
          </Button>
        </Stack>
      </form>
    </Paper>
  );
};

export default observer(AddFieldForm);
