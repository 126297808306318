import { Box, IconButton } from "@mui/material";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import AppBar from "./components/app-bar";
import { ArrowLeftIcon, ArrowRightIcon } from "./components/icons";
import Loader from "./components/loader/loader";
import Navigation from "./components/navigation";
import { useStore } from "./hooks";

export interface IReportsRouteProps {
  children: React.ReactNode;

  [option: string]: any;
}

const ReportsRouteView: FC<IReportsRouteProps> = ({ children, ...rest }) => {
  const { userStore, isLoading, reportsStore } = useStore();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const hasReports = Boolean(reportsStore.reports?.length);

  useEffect(() => {
    if (userStore.isAuthorized) {
      reportsStore.getEmbedToken().then(() => {
        reportsStore.getReports();
      });
    }
  }, [userStore.isAuthorized, reportsStore]);

  useEffect(() => {
    if (!userStore.userInfo && userStore.isAuthorized) {
      userStore.fetchUser();
    }
  }, [userStore]);

  if (!userStore.isAuthorized) {
    return <Redirect to="/login" />;
  }

  if (userStore.isAdmin) {
    return <Redirect to="/admin" />;
  }

  return (
    <Route {...rest}>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          display: "flex",
          backgroundColor: (theme) => theme.palette.grey[100],
        }}
      >
        <AppBar open={open} toggleDrawer={toggleDrawer} />
        {hasReports && <Navigation open={open} toggleDrawer={toggleDrawer} />}
        <Box
          component="main"
          sx={{
            backgroundColor: "white",
            mt: 8,
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "auto",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              p: 3,
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            {hasReports && (
              <IconButton
                onClick={toggleDrawer}
                sx={{ position: "absolute", left: "9px", top: "9px" }}
                size="small"
                disableRipple
                disableFocusRipple
              >
                {open ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              </IconButton>
            )}
            {children}
          </Box>
        </Box>
      </Box>
    </Route>
  );
};

export default observer(ReportsRouteView);
