import { Box, Button, ButtonProps, styled } from "@mui/material";
import { observer } from "mobx-react";
import { useContext, useMemo } from "react";

import { MARKETPLACES } from "../../constants";
import { CoreContext } from "../../core-context";
import { useStore } from "../../hooks";
import { MarketplacesType } from "../../types";

const BUTTON_COLORS: Record<MarketplacesType, string> = {
  WB: "#8E04A9",
  OZON: "#005bff",
};

interface StyledButtonProps extends ButtonProps {
  view: MarketplacesType;
  selected: boolean;
}

const StyledButton = styled(Button)<StyledButtonProps>(
  ({ view, selected }) => ({
    fontWeight: 600,
    color: selected ? "white" : BUTTON_COLORS[view],
    borderColor: BUTTON_COLORS[view],
    backgroundColor: selected ? BUTTON_COLORS[view] : undefined,
    height: "31px",
    marginRight: "16px",
    "&:hover, &:active": {
      borderColor: BUTTON_COLORS[view],
      backgroundColor: selected ? BUTTON_COLORS[view] : undefined,
    },
  })
);

const Marketplaces = () => {
  const { reportsStore } = useStore();
  const { setMarketplaceType, marketplaceType } = useContext(CoreContext);

  const filteredMarketplaces = useMemo(() => {
    if (!reportsStore.reports) {
      return [];
    }

    return MARKETPLACES.filter((mp) => {
      const hasReport = (reportsStore.reports || []).find(
        (report) => report.type_marketplace === mp.value
      );

      return Boolean(hasReport);
    });
  }, [reportsStore.reports]);

  return (
    <Box sx={{ ml: 3 }}>
      {filteredMarketplaces.map((item) => (
        <StyledButton
          variant="outlined"
          view={item.value}
          selected={marketplaceType === item.value}
          key={item.value}
          onClick={() => {
            setMarketplaceType(item.value);
          }}
        >
          {item.label}
        </StyledButton>
      ))}
    </Box>
  );
};

export default observer(Marketplaces);
