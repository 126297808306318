import type { MutableRefObject, RefCallback } from "react";
import { useMemo } from "react";

export type Ref<T> = RefCallback<T> | MutableRefObject<T>;

const assignRef = <T = any>(ref: Ref<T> | null | undefined, value: T) => {
  if (!ref) {
    return;
  }

  if (typeof ref === "function") {
    ref(value);
    return;
  }

  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
};

export const mergeRefs =
  <T>(...refs: Array<Ref<T> | null | undefined>) =>
    (node: T | null) => {
      refs.forEach((ref) => {
        assignRef(ref, node);
      });
    };

const useMergeRefs = <T>(...refs: Array<Ref<T> | null | undefined>) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => mergeRefs(...refs), refs);
};


export default useMergeRefs;