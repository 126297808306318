import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FC, memo } from "react";
import { Control, Controller, FieldError } from "react-hook-form";

interface IMuiFormRadioGroup {
  name: string;
  label: string;
  error?: FieldError;
  control: Control<any>;
  items: Array<{ label: string; value: string }>;
  defaultValue?: string;
}

const MuiFormRadioGroup: FC<IMuiFormRadioGroup> = ({
  label,
  name,
  error,
  control,
  items,
  defaultValue,
}) => {
  return (
    <FormControl error={Boolean(error?.message)} sx={{ width: "100%" }}>
      <Controller
        render={({ field }) => (
          <>
            <FormLabel>{label}</FormLabel>
            <RadioGroup row {...field} defaultValue={defaultValue}>
              {items.map((item) => (
                <FormControlLabel
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          </>
        )}
        name={name}
        control={control}
      />

      {error?.message && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default memo(MuiFormRadioGroup);
