import { SxProps,Theme } from "@mui/material/styles";

const styles: Record<string, SxProps<Theme>> = {
  table: {
    "& .MuiTableCell-root": {
      p: 1,
    },
  },
};

export default styles;
