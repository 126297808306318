import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, Button, Chip, Link,Typography } from "@mui/material";
import { FC, ReactNode, useCallback } from "react";
import { useHistory } from "react-router-dom";

import styles from "./styles";

interface IAction {
  label: string;
  text: string | ReactNode;
  onClick?: () => void;
  href?: string;
  buttonText: string;
}

const EmptyReports: FC = () => {
  const { push } = useHistory();

  const goDemo = useCallback(() => {
    push("/demo");
  }, [push]);

  const actions: IAction[] = [
    {
      label: "Попробовать демо-версию",
      text: "Ознакомьтесь с возможностями сервиса, получив доступ к демо-версии отчётов.",
      onClick: goDemo,
      buttonText: "Посмотреть демо",
    },
    {
      label: "Получить консультацию специалиста",
      text: "Наши эксперты готовы помочь вам разобраться в сервисе и предложить лучшее решение для вашего бизнеса.",
      href: "https://t.me/effstepsalesdir",
      buttonText: "Связаться с нами",
    },
    {
      label: "Я готов оплатить и хочу получить доступ",
      text: (
        <Box sx={{ whiteSpace: "pre-line" }}>
          <Typography variant="body2">
            Подключайте свои кабинеты МП в настройках текущего профиля.
          </Typography>
          <Typography variant="body2" component="span">
            Наша{" "}
          </Typography>
          <Link
            href="https://docs.google.com/document/d/1XI8wEpJaqGgsJ7nxL1NZOHGi7aUP7TLa7QjZ2LD4h20/edit?tab=t.0"
            underline="hover"
            target="_blank"
          >
            инструкция
          </Link>
          <Typography variant="body2" component="span">
            {" "}
            поможет во всем разобраться.
          </Typography>
          <Typography variant="body2">
            Полный доступ к аналитике активируется после оплаты!
          </Typography>
        </Box>
      ),
      href: "https://t.me/effstepsalesdir",
      buttonText: "Получить доступ",
    },
  ];

  return (
    <Box sx={styles.root}>
      <Box mr={2}>
        <PriorityHighIcon sx={styles.icon} color="info" />
      </Box>
      <Box>
        <Typography mb={1} variant="h5">
          Доступ к отчётам временно отсутствует
        </Typography>
        <Typography variant="body1">
          Похоже, что у вас пока нет доступа к отчётам. Но у нас есть несколько
          вариантов, чтобы помочь вам:
        </Typography>

        <Box mt={3}>
          {actions.map((action, index) => (
            <Box sx={styles.action}>
              <Box mr={2}>
                <Chip label={index + 1} color="primary" variant="outlined" />
              </Box>
              <Box>
                <Typography variant="body1">{action.label}</Typography>
                <Typography variant="body2">{action.text}</Typography>
                <Button
                  variant="text"
                  size="small"
                  href={action.href}
                  onClick={action.onClick}
                  sx={{ mt: 2 }}
                >
                  {action.buttonText}
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyReports;
