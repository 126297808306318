import { TextFieldProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import type { FactoryOpts } from "imask";
import React, { forwardRef, memo } from "react";

import { mergeRefs, useMask } from "../../hooks";

export type MaskOptions = {
  mask: FactoryOpts;
};

type IMaskTextFieldProps = TextFieldProps & {
  mask: MaskOptions;
  onAccept?: () => void;
  onComplete?: () => void;
}

const MuiFormMaskedInput = forwardRef<
  HTMLInputElement,
  IMaskTextFieldProps
>(({
  mask,
  onAccept,
  onComplete,

  ...otherProps
}, forwardedRef) => {


  const { maskValue, inputRef } = useMask({
    onAccept,
    onComplete,
    maskOptions: mask.mask,
  });

  const ref = mergeRefs(forwardedRef, inputRef);

  return (
    <TextField
      {...otherProps}
      value={maskValue}
      inputRef={ref}
    />
  );
});

export default memo(MuiFormMaskedInput);
