import { yupResolver } from "@hookform/resolvers/yup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { MuiFormPhoneInput } from "../components/form-controls";
import Loader from "../components/loader/loader";
import Support from "../components/support";
import { useStore } from "../hooks";
import { RegistrationData } from "../store/user-store";
import yup from "../yup-extended";

const Registration: FC = () => {
  const {
    userStore: { registration, isLoading },
  } = useStore();
  const { push } = useHistory();

  const schema = yup
    .object({
      fio: yup.string().required(),
      email: yup.string().required().email("Некорретный email"),
      phone: yup.string().isPhone().required(),
      password: yup.string().required().min(6, "Минимум 6 символов"),
      promo: yup.string()
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RegistrationData>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<RegistrationData> = (
    data: RegistrationData
  ) => {
    registration(data).then(() => {
      push("/login");
    });
  };

  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url("${process.env.PUBLIC_URL}/login.jpg")`,

            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "left",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Регистрация
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="fio"
                label="ФИО"
                autoComplete="fio"
                autoFocus
                error={!!errors.fio}
                helperText={errors.fio?.message}
                {...register("fio")}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                id="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                {...register("email")}
              />
              <MuiFormPhoneInput
                control={control}
                helperText={errors.phone?.message}
                error={!!errors.phone}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register("password")}
              />
              <TextField
                margin="normal"
                fullWidth
                label="ПРОМОКОД"
                id="promo"
                error={!!errors.promo}
                helperText={errors.promo?.message}
                {...register("promo")}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Зарегистрироваться
              </Button>
              <Grid container>
                <Grid item>
                  <Link href="/login" variant="body2">
                    Уже зарегистрированы? Войти тут
                  </Link>
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <Grid item>
                  <Typography align="center" variant="caption">
                    Нажимая «Зарегистрироваться», Вы подтверждаете, что прочли и
                    в полной мере осознаете условия обслуживания и положения
                    политики конфиденциальности.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Loader isLoading={isLoading} />
          </Box>
        </Grid>
      </Grid>
      <Support />
    </Box>
  );
};

export default observer(Registration);
