import { Box, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useContext, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import EmptyReports from "../components/empty-reports";
import PowerBiReport from "../components/power-bi-report";
import { CoreContext } from "../core-context";
import useStore from "../hooks/use-store";

const reportContainerStyle: SxProps<Theme> = () => ({
  height: "100%",
  width: "100%",

  ".report-container": {
    height: "100%",
    width: "100%",
  },

  iframe: {
    border: "none",
  },
});

const Dashboard: FC = () => {
  const {
    reportsStore: { reports, isLoading },
  } = useStore();
  const { marketplaceType } = useContext(CoreContext);

  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id && reports && reports.length > 0) {
      push(`/dashboard/${reports[0].type_report}`);
    }
  }, [id, reports, push]);

  const report = useMemo(() => {
    if (!reports) {
      return null;
    }

    return reports?.find(
      (item) => item.type_report === id && item.type_marketplace === marketplaceType
    );
  }, [id, reports, marketplaceType]);

  if (reports !== null && !reports?.length && !isLoading) {
    return <EmptyReports />;
  }

  if (report?.email && report.embedtoken && report.embedurl) {
    return (
      <Box sx={reportContainerStyle}>
        <PowerBiReport
          email={report?.email}
          accessToken={report.embedtoken}
          embedUrl={report.embedurl}
        />
      </Box>
    );
  }

  return (
    <iframe
      title="dashboard"
      src={report?.url_report}
      style={{ border: "none", height: "100%", width: "100%" }}
      width="100%"
    ></iframe>
  );
};

export default observer(Dashboard);
