import { Notifications } from "@mui/icons-material";
import {
  Badge,
  Button,
  IconButton,
  Popover,
  Stack,
  StackProps,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { observer } from "mobx-react";
import { FC, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";

import { useStore } from "../../../../hooks";
import styles from "./styles";

interface StyledNotificationProps extends StackProps {
  level: string;
}

const NOTIFICATIONS_COLOR: Record<string, string> = {
  INFO: "#d0e1f2",
  WARNING: "#faecbe",
  ERROR: "#f5aca6",
};

const StyledNotification = styled(Stack)<StyledNotificationProps>(
  ({ level }) => ({
    padding: 16,
    backgroundColor: NOTIFICATIONS_COLOR[level],
  })
);

const BarNotifications: FC = () => {
  const {
    userNotificationsStore: { userNotifications },
  } = useStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const { push } = useHistory();
  const isEmpty = !userNotifications?.personal.length;

  const onOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (anchorEl) {
        setAnchorEl(null);
      } else {
        setAnchorEl(event.currentTarget);
      }
    },
    [anchorEl]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const goNotificationLink = (link?: string) => {
    push(link || "/settings");
    onClose();
  };

  return (
    <Box>
      <Tooltip title="Уведомления">
        <IconButton onClick={onOpenMenu} size="medium" sx={{ ml: 1 }}>
          <Badge
            color="error"
            badgeContent={userNotifications?.personal.length}
            invisible={!userNotifications?.personal.length}
          >
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disablePortal={false}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack gap={1}>
          {isEmpty && (
            <Box sx={styles.empty}>
              <Typography>У вас пока нет уведомлений</Typography>
            </Box>
          )}
          {!isEmpty &&
            userNotifications?.personal.map((notification) => (
              <StyledNotification
                alignItems="flex-start"
                level={notification.level}
              >
                <Typography variant="body1">{notification.title}</Typography>
                <Typography variant="body2">{notification.message}</Typography>
                {notification.buttonLink && (
                  <Button
                    size="small"
                    sx={{ mt: 2 }}
                    variant="contained"
                    color="primary"
                    onClick={() => goNotificationLink(notification.buttonLink)}
                  >
                    {notification.buttonText || "Подробнее"}
                  </Button>
                )}
              </StyledNotification>
            ))}
        </Stack>
      </Popover>
    </Box>
  );
};

export default observer(BarNotifications);
