import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import AdminRouteView from "./admin-route-view";
import Settings from "./components/settings";
import { IS_ADMIN_STORAGE_NAME, MARKETPLACE_STORAGE_NAME, TOKEN_STORAGE_NAME } from "./constants";
import { CoreContext } from "./core-context";
import { useStore } from "./hooks";
import Notifier from "./Notifier";
import AdminMain from "./pages/admin-main";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Registration from "./pages/registration";
import PopupProvider from "./popup-provider";
import ReportsRouteView from "./reports-route-view";
import RequestFactory from "./request/request-factory";
import RouteView from "./route-view";
import { MarketplacesType } from "./types";
import storage from "./utils/storage";

const App: FC<{ createRequest: RequestFactory["createRequest"] }> = ({ createRequest }) => {
  const { userStore, userNotificationsStore, } = useStore();
  const [marketplaceType, setMarketplaceType] = useState<MarketplacesType>(storage.get(MARKETPLACE_STORAGE_NAME) as MarketplacesType || "WB");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#56AA82",
        light: "#E3F1E6",
        contrastText: "#fff",
      },
    },
  });

  if (storage.get(TOKEN_STORAGE_NAME)) {
    userStore.setAuthorized(true);
  }

  const setMarketplace = useCallback((value: MarketplacesType) => {
    setMarketplaceType(value);
    storage.set(MARKETPLACE_STORAGE_NAME, value);
  }, [setMarketplaceType]);

  const isAdmin = storage.get(IS_ADMIN_STORAGE_NAME);
  if (isAdmin) {
    userStore.setIsAdmin(Boolean(isAdmin === "true"));
  }

  if (
    !userNotificationsStore.userNotifications &&
    userStore.isAuthorized
  ) {
    userNotificationsStore.fetchUserNotifications();
  }

  return (
    <ThemeProvider theme={theme}>
      <CoreContext.Provider value={{ createRequest, marketplaceType, setMarketplaceType: setMarketplace }}>
        <CssBaseline />
        <Router>
          <Notifier />
          <PopupProvider />
          <Switch>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/demo">
              <Login isDemo />
            </Route>
            <Route exact path="/registration">
              <Registration />
            </Route>
            <ReportsRouteView exact path="/">
              <Dashboard />
            </ReportsRouteView>
            <ReportsRouteView exact path="/dashboard/:id">
              <Dashboard />
            </ReportsRouteView>
            <RouteView exact path={["/settings", "/settings/:id"]}>
              <Settings />
            </RouteView>
            <AdminRouteView exact path="/admin">
              <AdminMain />
            </AdminRouteView>
          </Switch>
        </Router>
      </CoreContext.Provider>
    </ThemeProvider>
  );
};

export default App;
