import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { observer } from "mobx-react";
import { FC, useContext, useEffect, useMemo } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";

import { CoreContext } from "../../core-context";
import { useStore } from "../../hooks";
import { NavigationItemsIcons } from "./list-items";

interface IPropsNavigation {
  open: boolean;
  toggleDrawer: () => void;
}

const drawerWidth: number = 146;

const listItemButtonSx: SxProps<Theme> = (theme: Theme) => ({
  padding: 0,
  minHeight: "33px !important",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&.active": {
    backgroundColor: theme.palette.primary.light,
  },
});

const listItemIconSx: SxProps<Theme> = (theme: Theme) => ({
  color: (theme) => theme.palette.primary.main,
  width: "16px",
  height: "16px",

  "& svg": {
    width: "16px",
    height: "16px",
  },
});

const DrawerComponent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginRight: open ? "8px" : "0px",
  paddingTop: "64px",

  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    borderRadius: "8px",
    border: "none",
    width: drawerWidth,
    height: "100%",
    backgroundColor: theme.palette.grey[100],
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: "0px",
    }),
  },
}));

const Navigation: FC<IPropsNavigation> = ({ open }) => {
  const {
    reportsStore: { reports },
  } = useStore();
  const { marketplaceType } = useContext(CoreContext);
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();

  const filteredReports = useMemo(
    () =>
      (reports || []).filter(
        (report) => report.type_marketplace === marketplaceType
      ),
    [marketplaceType, reports]
  );

  useEffect(() => {
    const reportExist = filteredReports.find(report => report.type_report === id);

    if (!reportExist) {
      push(`/dashboard/${filteredReports[0]?.type_report}`);
    }
  }, [id, filteredReports, push]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          marginRight: "8px",
          marginLeft: "10px",
          paddingTop: "64px",
          borderRadius: "8px",
          "& .MuiListItemButton-root": {
            minHeight: "48px",
          },
          "& .MuiListItemIcon-root": {
            minWidth: "0",
          },
        }}
      >
        <List
          component="nav"
          sx={{
            backgroundColor: "white",
            height: "100%",
            width: "32px",
          }}
        >
          {filteredReports?.map((item) => (
            <ListItemButton
              key={item.type_report}
              component={NavLink}
              to={{
                pathname: `/dashboard/${item.type_report}`,
                search: window.location.search,
              }}
              sx={listItemButtonSx}
              disableRipple
              disableTouchRipple
            >
              <ListItemIcon sx={listItemIconSx}>
                {NavigationItemsIcons[item.type_report]}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Box>

      <DrawerComponent variant="permanent" open={open}>
        <List
          component="nav"
          sx={{ backgroundColor: "white", height: "100%", width: "146px" }}
        >
          {filteredReports?.map((item) => (
            <ListItemButton
              key={item.type_report}
              component={NavLink}
              to={{
                pathname: `/dashboard/${item.type_report}`,
                search: window.location.search,
              }}
              sx={listItemButtonSx}
              disableRipple
              disableTouchRipple
            >
              <ListItemText
                primary={item.note_r}
                sx={{ color: (theme) => theme.palette.primary.main, ml: "8px" }}
              />
            </ListItemButton>
          ))}
        </List>
      </DrawerComponent>
    </>
  );
};

export default observer(Navigation);
