import "./index.css";

import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import RequestConfigList from "./request/request-config-list";
import RequestFactory, { IResponse } from "./request/request-factory";
import { RootStore } from "./store/root-store";
import { StoreContextProvider } from "./store-context";

const root = ReactDOM.createRoot(
  document.getElementById("root") as ReactDOM.Container
);

const store = new RootStore();

const onError = (res: IResponse<any>): void => {
  if (res.status === "401") {
    store.userStore.setAuthorized(false);
    store.notificationStore.addNotification({
      text: "Ошибка авторизации! Попробуйте войти снова ",
      type: "error",
    });
  } else {
    store.notificationStore.addNotification({
      text: res.message || "Произошла ошибка",
      type: "error",
    });
  }
};

const requestFactory = new RequestFactory({
  requestConfigList: RequestConfigList,
  onError,
});
const createRequest = requestFactory.createRequest.bind(requestFactory);
store.setCreateRequest(createRequest);

root.render(
  <React.StrictMode>
    <SnackbarProvider autoHideDuration={5000} preventDuplicate>
      <StoreContextProvider value={store}>
        <App createRequest={createRequest} />
      </StoreContextProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
