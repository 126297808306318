import { Download, Upload } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Stack,
  styled, TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Badge, { badgeClasses } from "@mui/material/Badge";
import { observer } from "mobx-react";
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";

import { useStore } from "../../hooks";
import { IUser } from "../../store/admin-store";
import { IGoodsItem } from "../../store/goods-store";
import { clearEmptyFieldiInObject } from "../../utils/utils";
import Loader from "../loader/loader";
import Table, { Cell } from "../table/table";
import AddFieldForm from "./components/add-field-form";
import Filters from "./components/filters";
import ImportTooltip from "./components/import-tooltip";
import locale from "./locale";
import styles from "./styles";

interface IGoodsProps {
  user?: IUser | null;
}

const CartBadge = styled(Badge)`
    & .${badgeClasses.badge} {
        top: -12px;
        right: -6px;
    }
`;


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Goods: FC<IGoodsProps> = () => {
  const {
    goodsStore: {
      fetchGoods,
      addGood,
      editGood,
      deleteGood,
      setPaginationParams,
      goods,
      exportFile,
      importFile,
      paginationParams,
      totalCount,
      isLoading,
      filters,
    },
    referenceBooks: {
      clientIds
    }
  } = useStore();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editData, setEditData] = useState<IGoodsItem | undefined>();
  const [showFilters, setShowFilters] = useState(false);

  const headerCells = useMemo(() => locale.headerCells, []);

  const onAdd = useCallback(
    (data: IGoodsItem) => {
      if (Boolean(editData)) {
        editGood(data);
      } else {
        addGood(data);
      }

      setShowAddForm(false);
    },
    [editData, addGood, editGood]
  );

  const toggleShowAdd = useCallback(() => {
    if (!showAddForm) {
      setEditData(undefined);
      setShowAddForm(true);
    }
  }, [showAddForm]);

  const onCancelAdd = useCallback(() => {
    setShowAddForm(false);
  }, []);

  const onRemoveField = useCallback((data: IGoodsItem) => {
    if (data.id) {
      deleteGood(data.id);
    }
  }, [deleteGood]);

  const onEditField = useCallback((id: number) => {
    const editToken = goods?.find((token) => token.id === id);
    setEditData(editToken);
    setShowAddForm(true);
  }, [goods]);

  const renderHeaderCell = useCallback(
    (cell: Cell) => (
      <TableCell variant="footer" key={cell.name} align="left" width={cell.width}>
        {cell.name === "edit" || cell.name === "delete" ? "" : cell.name}
      </TableCell>
    ),
    []
  );

  const renderRow = useCallback(
    (field: IGoodsItem, index: number) => {
      return (
        <TableRow key={index}>
          <TableCell align="left">
            {field.wbClientLabel}
          </TableCell>
          <TableCell align="left">
            {field.ozonClientLabel}
          </TableCell>
          <TableCell align="left">{field.brand}</TableCell>
          <TableCell align="left">{field.subject}</TableCell>
          <TableCell align="left">{field.sellerArticle}</TableCell>
          <TableCell align="left">{field.wbArticle}</TableCell>
          <TableCell align="left">{field.ozonSku}</TableCell>
          <TableCell align="left">{field.description}</TableCell>
          <TableCell align="left">{field.size}</TableCell>
          <TableCell align="left">{field.barcode}</TableCell>
          <TableCell align="left">{field.rentalPeriod}</TableCell>
          <TableCell align="left">{field.deliveryTime}</TableCell>
          <TableCell align="left">{field.costPrice}</TableCell>
          <TableCell align="left">{field.priceApplicationDate}</TableCell>
          <TableCell align="left">{field.manager}</TableCell>
          <TableCell align="left">{field.active ? "Да" : "Нет"}</TableCell>

          {field.id && (
            <TableCell align="left">
              <IconButton size="small" onClick={() => onEditField(field.id!)}>
                <EditIcon color="action" />
              </IconButton>
            </TableCell>
          )}
          {field.id && (
            <TableCell align="left">
              <IconButton size="small" onClick={() => onRemoveField(field)}>
                <DeleteIcon color="action" />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      );
    },
    [onEditField, onRemoveField, clientIds]
  );

  const onChangePage = useCallback((page: number) => {
    setPaginationParams({ page });
  }, [setPaginationParams]);

  const onChangePerPage = useCallback((value: number) => {
    setPaginationParams({ limit: value, page: 1 });
  }, [setPaginationParams]);

  const toggleShowFilters = useCallback(() => {
    setShowFilters((prevValue) => !prevValue);
  }, []);

  const onCloseFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  const onExport = useCallback(() => {
    exportFile();
  }, []);

  const onImport = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file, file.name);

      importFile(formData);
    }
  }, []);

  useEffect(() => {
    fetchGoods();
  }, [paginationParams]);

  return (
    <Box sx={styles.wrapper}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <IconButton color="primary" onClick={toggleShowFilters}>
          <FilterListIcon />
          <CartBadge
            badgeContent={Object.keys(filters).length}
            color="primary"
            overlap="circular"
          />
        </IconButton>


        <Box>
          <ImportTooltip hasGoods={Boolean(goods?.length)}>
            <Button
              component="label"
              variant="contained"
              sx={{ mr: 2 }}
              startIcon={<Upload />}
            >
              Импорт
              <VisuallyHiddenInput
                type="file"
                onChange={onImport}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </Button>
          </ImportTooltip>

          <Button
            variant="outlined"
            startIcon={<Download />}
            onClick={onExport}
            sx={{ mr: 2 }}
          >
            Экспорт
          </Button>

          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={toggleShowAdd}
            sx={{ mr: 2 }}
          >
            Добавить
          </Button>
        </Box>
      </Stack>

      <Filters show={showFilters} onCloseFilters={onCloseFilters} />

      {!goods?.length && !isLoading && (
        <Typography variant="caption" mt={4}>
          Нет товаров подходящих под фильтры, либо вы пока не добавили товары
        </Typography>
      )}
      {Boolean(goods?.length) && (
        <Table
          headerCells={headerCells}
          fields={goods as IGoodsItem[]}
          renderHeaderCell={renderHeaderCell}
          renderRow={renderRow}
          totalCount={totalCount}
          onChangePage={onChangePage}
          onChangePerPage={onChangePerPage}
        />
      )}

      <Loader isLoading={isLoading} />

      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={showAddForm}
      >
        <Box sx={styles.addForm} id="add-form">
          {showAddForm && (
            <AddFieldForm
              onAdd={onAdd}
              onCancel={onCancelAdd}
              defaultData={editData}
            />
          )}
        </Box>
      </Backdrop>
    </Box>
  );
};

export default observer(Goods);
