import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

const styles: Record<string, SxProps<Theme>> = {
  empty: {
    padding: 2,
  },
};

export default styles;
