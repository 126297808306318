import { Fade, Tooltip } from "@mui/material";
import { FC, ReactElement } from "react";

interface ImportTooltipProps {
  hasGoods: boolean;
  children: ReactElement;
}

const ImportTooltip: FC<ImportTooltipProps> = ({ hasGoods, children }) => {
  if (hasGoods) {
    return (
      <Tooltip
        title="Обратите внимание! Импорт перетрет все ваши товары в таблице"
        placement="top"
        slots={{
          transition: Fade,
        }}
        slotProps={{
          transition: { timeout: 600 },
        }}
        arrow
      >
        {children}
      </Tooltip>
    );
  }
  
  return children;
};

export default ImportTooltip;
