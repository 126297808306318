import {
  Box,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
} from "@mui/material";
import { FC, memo, ReactNode, useState } from "react";

import styles from "./styles";

export type Cell = {
  name: string;
  width: string;
};

export interface ITableProps {
  fields: Array<any>;
  headerCells: Array<Cell>;
  renderRow: (field: any, index: number) => ReactNode;
  renderHeaderCell: (cell: Cell) => ReactNode;
  totalCount?: number;
  rowsPerPageOptions?: TablePaginationProps["rowsPerPageOptions"];
  onChangePage?: (page: number) => void;
  onChangePerPage?: (value: number) => void;
}

const Table: FC<ITableProps> = ({
  fields,
  headerCells,
  renderRow,
  renderHeaderCell,
  totalCount,
  rowsPerPageOptions = [10, 20, 50],
  onChangePage,
  onChangePerPage,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newPerPage);
    setPage(0);
    onChangePerPage?.(newPerPage);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onChangePage?.(newPage + 1);
  };

  return (
    <Box sx={styles.wrapper}>
      <TableContainer>
        <MuiTable stickyHeader sx={styles.table}>
          <TableHead>
            <TableRow selected sx={{ verticalAlign: "baseline" }}>
              {headerCells.map((cell) => renderHeaderCell(cell))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(fields || []).map((field, index) => renderRow(field, index))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {totalCount && onChangePage && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Показывать по"
        />
      )}
    </Box>
  );
};

export default memo(Table);
